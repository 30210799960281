$(document).ready(function() {
  var $browser = $(window).width();

  if($browser < 992) {
    $('.mobile-menu-toggle').click(function() {
      $('.primary-menu-navigation').slideToggle();
    });

    $('.sf-with-ul').removeAttr('href');

    $('.sf-with-ul').click(function() {
      $('.sub-menu').slideUp();
      $(this).closest('.menu-item').find('.sub-menu').slideDown();
    });

    /*
    $('.sub-menu-about-toggle').click(function() {
      $('.sub-menu-about').slideDown();
      $('.sub-menu-exhibitions, .sub-menu-publications, .sub-menu-media, .sub-menu-research').slideUp();
    });

    $('.sub-menu-exhibitions-toggle').click(function() {
      $('.sub-menu-exhibitions').slideDown();
      $('.sub-menu-about, .sub-menu-publications, .sub-menu-media, .sub-menu-research').slideUp();
    });

    $('.sub-menu-publications-toggle').click(function() {
      $('.sub-menu-publications').slideDown();
      $('.sub-menu-about, .sub-menu-exhibitions, .sub-menu-media, .sub-menu-research').slideUp();
    });

    $('.sub-menu-media-toggle').click(function() {
      $('.sub-menu-media').slideDown();
      $('.sub-menu-about, .sub-menu-exhibitions, .sub-menu-publications, .sub-menu-research').slideUp();
    });

    $('.sub-menu-research-toggle').click(function() {
      $('.sub-menu-research').slideDown();
      $('.sub-menu-about, .sub-menu-exhibitions, .sub-menu-publications, .sub-menu-media').slideUp();
    });
    */
  }
});
